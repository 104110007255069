import React from 'react';
import { withRouter } from 'react-router-dom';
import "./navigation.styles.scss";

import {Navbar, Nav, NavDropdown} from "react-bootstrap";

import NAVIGATION_DATA from './navigation.data';

const NavigationComponent = () => {
    const navInfo = NAVIGATION_DATA;
    return(
        <>
            <div className="container-fluid top-nav">
                <div className="row d-flex justify-content-between">
                <Navbar.Brand href="/"><img id="main-logo" src={navInfo.navLogo} alt="Website Logo"/></Navbar.Brand>
                {navInfo.logoDivider && <img src={navInfo.logoDivider} alt="Partner Logo"/>}
                {navInfo.navLogo2 && <Navbar.Brand id="alt-logo" href="/"><img src={navInfo.navLogo2} alt="Partner Logo"/></Navbar.Brand> }
                </div>
            </div>
            <div className="container-fluid top-nav-line">
                <div className="row d-flex justify-content-between main-nav vertical-center">
                    <div className="col-12 optum-menu-nav">
            <Navbar collapseOnSelect expand="lg" className="navbar optum-nav navbar-light mobile-nav">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="">
                        {
                            navInfo.linkItems.map( ({ id, ...otherSectionProps }) => (
                                <>
                                    { otherSectionProps.children.length > 0 &&
                                    <NavDropdown className="" title=<span>{otherSectionProps.name}</span>
                                                 id="collapsible-nav-dropdown">
                                        {otherSectionProps.children.map(({name, link}) => (
                                            <NavDropdown.Item href={link}>{name}</NavDropdown.Item>
                                        ))
                                        }
                                    </NavDropdown>
                                    }

                                    { otherSectionProps.children.length === 0 &&
                                        <div className="nav-item">
                                            <Nav.Link key={id} href={otherSectionProps.link}><span>{otherSectionProps.name}</span></Nav.Link>
                                        </div>
                                            }
                                </>
                            ))
                        }
                    </Nav>
                    {navInfo.rightHtml &&
                    <div dangerouslySetInnerHTML={{__html: navInfo.rightHtml}} />
                    }
                </Navbar.Collapse>
            </Navbar>
                    </div>
                </div>
            </div>
        </>
    );
}

export default withRouter(NavigationComponent);
